import { ErrorBoundary } from "./features/shared/ErrorBoundary";
import { CatchErrorPage } from "./pages/CatchErrorPage";

type RootErrorBoundaryProps = { children: React.ReactNode };

export const RootErrorBoundary = (props: RootErrorBoundaryProps) => {
  const { children } = props;
  return (
    <ErrorBoundary
      render={(state) => (state.hasError ? <CatchErrorPage /> : children)}
    />
  );
};
