import React from "react";

declare const window: Window & {
  dataLayer: any[];
  Didomi?: any;
  didomiConfig: any;
  didomiEventListeners: any[];
  didomiOnReady: any[];
  gdprAppliesGlobally: boolean;
  showDidomi: () => void;
  Tracking: { accept: () => void; reject: () => void };
};

window.dataLayer = window.dataLayer || [];
window.didomiConfig = window.didomiConfig || {};
window.didomiEventListeners = window.didomiEventListeners || [];
window.didomiOnReady = window.didomiOnReady || [];
window.gdprAppliesGlobally = true;
window.showDidomi = () => {
  if (window.Didomi && !localStorage.getItem("euconsent-v2")) {
    window.Didomi.notice.show();
  }
};
window.Tracking = {
  accept: () => {
    if (window.Didomi && !localStorage.getItem("euconsent-v2")) {
      window.Didomi.notice.show();
    }
  },
  reject: () => {
    if (window.Didomi) {
      window.Didomi.setUserDisagreeToAll();
    }
  },
};

type TrackingProps = { didomiApiKey: string; gtmApiKey: string };

export const Tracking = (props: TrackingProps) => {
  const { didomiApiKey, gtmApiKey } = props;

  React.useEffect(() => {
    const id = "Tracking";

    if (!document.getElementById(id)) {
      // prettier-ignore
      // @ts-ignore
      (function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");})();
      window.didomiOnReady.push(function () {
        if (window.document.cookie.includes("iOS App Store")) {
          window.Didomi.notice.hide();
        }
      });
      window.didomiOnReady.push(function () {
        // prettier-ignore
        // @ts-ignore
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',gtmApiKey);
      });

      const script = document.createElement("script");
      script.async = true;
      script.id = id;
      script.src = `https://sdk.privacy-center.org/${didomiApiKey}/loader.js?target=${window.location.hostname}`;
      document.head.insertBefore(script, document.head.childNodes[0]);
    }
  }, [didomiApiKey, gtmApiKey]);

  return null;
};
