export function initialize() {
  const remove = () => {
    const splashScreenElement = document.getElementById("splash-screen");

    if (splashScreenElement) {
      splashScreenElement.remove();
    }

    return splashScreenElement;
  };

  return { remove };
}
