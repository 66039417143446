import { Amplify } from "@aws-amplify/core";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { AppConfig } from "../models/AppConfig";
import { HealthCheck } from "../models/HealthCheck";

export const publicApi = createApi({
  baseQuery: baseQuery({ baseUrl: "/gc/public" }),
  endpoints: (builder) => ({
    forgotPassword: builder.mutation<void, { email: string }>({
      query: (arg) => ({
        url: "/auth/reset-password",
        method: "POST",
        data: arg,
      }),
    }),
    getAppConfig: builder.query<AppConfig, void>({
      queryFn: async (_arg, _queryLifecycleApi, _extraOptions, fetch) => {
        const response = await fetch({ url: "/config", method: "GET" });

        if (response.error) {
          return { error: response.error };
        }

        const appConfig = response.data as AppConfig;
        Amplify.configure(appConfig.cognito);
        return { data: appConfig };
      },
    }),
    getHealthCheck: builder.query<HealthCheck, void>({
      query: () => ({ url: "/health-check", method: "GET" }),
    }),
    getLegalNotice: builder.query<string, void>({
      query: () => ({ url: "/legals/legal-notice", method: "GET" }),
    }),
    getTermsOfService: builder.query<string, void>({
      query: () => ({ url: "/legals/terms-of-service", method: "GET" }),
    }),
  }),
  reducerPath: "publicApi",
  tagTypes: [],
});

export const {
  useForgotPasswordMutation,
  useGetAppConfigQuery,
  useGetHealthCheckQuery,
  useLazyGetLegalNoticeQuery,
  useLazyGetTermsOfServiceQuery,
} = publicApi;

type BaseQueryContext = { baseUrl: string };

function baseQuery(context: BaseQueryContext): BaseQueryFn<
  AxiosRequestConfig & {
    responseHandler?: (response: AxiosResponse) => unknown;
  }
> {
  return async (arg) => {
    try {
      const { responseHandler, url } = arg;
      const response = await axios({ ...arg, url: `${context.baseUrl}${url}` });
      return {
        data: responseHandler ? responseHandler(response) : response.data,
      };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error: {
          data: error.response?.data ?? error.message,
          status: error.response?.status ?? 500,
        },
      };
    }
  };
}
