import React from "react";

type ScrollRestorerProps = {
  location: Pick<Location, "pathname" | "search"> & {
    state?: { preventScrollReset: boolean };
  };
};

export const ScrollRestorer = (props: ScrollRestorerProps) => {
  const { location } = props;

  React.useEffect(() => {
    if (!location.state?.preventScrollReset) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, location.search, location.state]);

  return null;
};
