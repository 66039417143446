export type QueryApiState<TData> = {
  data?: TData;
  error?: any;
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isUninitialized: boolean;
  refetch?: () => void;
};

export function getQueryApiStates<TData>() {
  const failed = (error: QueryApiState<TData>["error"]) => ({
    error,
    isError: true,
    isFetching: false,
    isLoading: false,
    isSuccess: false,
    isUninitialized: false,
  });

  const idle = () => ({
    isError: false,
    isFetching: false,
    isLoading: false,
    isSuccess: false,
    isUninitialized: true,
  });

  const loading = () => ({
    isError: false,
    isFetching: true,
    isLoading: true,
    isSuccess: false,
    isUninitialized: false,
  });

  const succeeded = (data: QueryApiState<TData>["data"]) => ({
    data,
    isError: false,
    isFetching: false,
    isLoading: false,
    isSuccess: true,
    isUninitialized: false,
  });

  return { failed, idle, loading, succeeded };
}
