const classNamePrefix = "ErrorsPage";

type ErrorsPageRootProps = { children: React.ReactNode };

const ErrorsPageRoot = (props: ErrorsPageRootProps) => {
  const { children } = props;
  return <div className={classNamePrefix}>{children}</div>;
};

type ErrorsPageTitleProps = { children: React.ReactNode };

const ErrorsPageTitle = (props: ErrorsPageTitleProps) => {
  const { children } = props;
  return <h1>{children}</h1>;
};

type ErrorsPageMessageProps = { children: React.ReactNode };

const ErrorsPageMessage = (props: ErrorsPageMessageProps) => {
  const { children } = props;
  return <p role="alert">{children}</p>;
};

export const ErrorsPage = Object.assign(ErrorsPageRoot, {
  Title: ErrorsPageTitle,
  Message: ErrorsPageMessage,
});
