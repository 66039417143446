import { useTranslation } from "react-i18next";

import { ErrorsReloadButton } from "../features/errors/shared/ErrorsReloadButton";
import { ErrorsPage } from "./shared/ErrorsPage";

export const CatchErrorPage = () => {
  const { t } = useTranslation();
  const { message, title } = t("pages.CatchErrorPage") as unknown as {
    message: string;
    title: string;
  };

  return (
    <ErrorsPage>
      <ErrorsPage.Title>{title}</ErrorsPage.Title>
      <ErrorsPage.Message>{message}</ErrorsPage.Message>
      <ErrorsReloadButton />
    </ErrorsPage>
  );
};
